.page-title {
    color: $primaryTextColor;
    position: relative;
    font-size: 20px;
    font-weight: 600;
    text-shadow: 0 4px 15px rgba(222, 187, 36, 0.4);
    text-align: center;
    margin-right: 0;
    margin-top: -20px;

    @media screen and (max-width: $tableWidth) {
        margin-top: 0;
        display: none;
    }

    &__without-sidebar {
        right: 84px;
    }
}
