.call-to-auth-modal-description {
  margin: auto;
  max-width: 372px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 32px;
}

.call-to-auth-modal-description-small {
  padding: 0 40px 20px 40px;
}