.page_caption {
    color: $primaryTextColor;
    position: relative;
    font-size: px(20);
    font-weight: 600;
    text-shadow: 0px 4px 15px rgba(222, 187, 36, 0.4);
    text-align: center;
    margin-right: 0px;
    margin-top: -20px;

    @media screen and (max-width: $tableWidth) {
        display: none;
    }
}

.app-wrapper:not(.app-wrapper--with-sidebar) {
    .page_caption {
        margin-right: 160px;
    }
}

.mobile_caption {
    display: none;
    padding: 17px 16px 0 16px;

    &.center {
        justify-content: center;
    }

    h1 {
        font-size: px(26);
        font-weight: 800;
        letter-spacing: 0.14em;
        text-shadow: 0px 4px 15px rgba(222, 187, 36, 0.4);
    }

    .btn {
        font-size: px(14);
        letter-spacing: 0.05em;
        padding: 6px 29px;
        filter: drop-shadow(0px 4px 10px rgba(27, 23, 1, 0.5));
        // box-shadow: 0px 2px 10px rgba(245, 223, 77, 0.3);
    }

    @media screen and (max-width: $tableWidth) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media screen and (max-width: 320px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
